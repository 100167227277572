<script>
  export let type;
  export let additionalClasses;
</script>

<div class={`${additionalClasses} float-left h-fit`}>
  {#if type === "posts" || type === "blog Posts"}
    <svg
      data-src="../../assets/images/blog_images/svg/square.svg"
      width="16"
      height="16"
      data-loading="lazy"
      class="doc-icon blog"
      stroke="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="https://www.w3.org/1999/xlink"
      viewBox="-40 -40 180 180"
      data-id="svg-loader_8"
    >
      <polygon
        stroke-width="20"
        stroke="#3b82f6"
        points="0,0 100,0 100,100 0,100"
        fill="transparent"
      />
    </svg>
  {:else if type === "publications"}
    <svg
      data-src="/static/images/svg/diamond.svg"
      width="16"
      height="16"
      data-loading="lazy"
      class="doc-icon paper"
      stroke="#ff4c7c"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="https://www.w3.org/1999/xlink"
      viewBox="-40 -40 180 180"
      data-id="svg-loader_9"
    >
      <polygon
        transform="rotate(45, 50, 50)"
        stroke-width="20"
        points="0,0 100,0 100,100 0,100"
        fill="transparent"
      />
    </svg>
  {:else}
    <svg
      data-src="/static/images/svg/triangle.svg"
      width="16"
      height="16"
      data-loading="lazy"
      class="doc-icon talk"
      stroke="#99cb33"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="https://www.w3.org/1999/xlink"
      viewBox="-40 -40 180 180"
      data-id="svg-loader_10"
    >
      <polygon stroke-width="20" points="0,0 80,50 0,100" fill="transparent" />
    </svg>
  {/if}
</div>
