<script>
  import Fuse from "fuse.js";
  import Icon from "./Icon.svelte";
  import { fade } from "svelte/transition";
  import { onMount } from "svelte";

  // export let id;
  let searchTerm = "";
  let isLoading = false;
  let searchData;
  let fuse;
  let searchResult = [];
  let groupedResults = {};
  let input;
  let placeholder = "Enter Your Search Term To See Results";
  const data = [];
  const fuseOptions = {
    isCaseSensitive: true,
    includeScore: true,
    shouldSort: true,
    includeMatches: true,
    findAllMatches: true,
    minMatchCharLength: 2,
    // location: 0,
    threshold: 0.5,
    // distance: 100,
    // useExtendedSearch: false,
    ignoreLocation: true,
    ignoreFieldNorm: true,
    // fieldNormWeight: 1,
    keys: [
      { name: "title", weight: 1 },
      { name: "authors", weight: 0.5 },
      { name: "conference_name", weight: 0.5 },
      { name: "abstract", weight: 0.1 },
    ],
  };

  function stopTyping(node) {
    const handleKeyup = debounce((event) => {
      if (node.contains(event.target)) {
        node.dispatchEvent(new CustomEvent("stopTyping")); // (3) fire the event
      }
    }, 500);

    document.addEventListener("keyup", handleKeyup, true);

    return {
      destroy() {
        document.removeEventListener("keyup", handleKeyup, true);
      },
    };
  }

  function debounce(inputFunction, timeToWaitBeforeFiringInMs = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        inputFunction.apply(this, args);
      }, timeToWaitBeforeFiringInMs);
    };
  }

  function handleInput(event) {
    searchTerm = event.target.value;
    runOnTypingFunction();
  }

  function runOnTypingFunction() {
    isLoading = true;
  }

  const fetchSearchResults = async (searchTerm) => {
    placeholder = "No Results Found";
    if (searchTerm.length === 0) {
      groupedResults = {};
      isLoading = false;
      placeholder = "Enter Your Search Term To See Results";
      return;
    }
    groupedResults = {};
    if (!searchData) {
      searchData = data;
    }
    if (searchData && !fuse) {
      fuse = new Fuse(searchData, fuseOptions);
    }
    if (!fuse) {
      isLoading = false;
      return;
    }
    searchResult = fuse.search(searchTerm);

    const maxResultsPerCategory = 5;
    const resultsCountPerCategory = {};

    searchResult.forEach((result) => {
      const { type } = result.item;

      if (!groupedResults[type]) {
        groupedResults[type] = [];
        resultsCountPerCategory[type] = 0;
      }

      if (resultsCountPerCategory[type] < maxResultsPerCategory) {
        groupedResults[type].push(result);
        resultsCountPerCategory[type]++;
      }
    });
    isLoading = false;
  };

  function clearSearchInput() {
    searchTerm = "";
    fetchSearchResults(searchTerm);
    input.focus();
  }

  let ul;
  let liSelected;
  let index = -1;

  function handleKeyDown(event) {
    if (ul) {
      const len = ul.getElementsByTagName("li").length - 1;
      if (event.which === 40) {
        index++;
        navigateList(len);
      } else if (event.which === 38) {
        index--;
        navigateList(len);
      }
    }
  }

  function navigateList(len) {
    if (liSelected) {
      removeClass(liSelected, "bg-neutral-500");
      const next = ul.getElementsByTagName("li")[index];

      if (typeof next !== "undefined" && index <= len && index >= 0) {
        liSelected = next;
      } else {
        index = index > len ? 0 : len;
        liSelected = ul.getElementsByTagName("li")[index];
      }

      addClass(liSelected, "bg-neutral-500");
    } else {
      index = 0;
      liSelected = ul.getElementsByTagName("li")[0];
      addClass(liSelected, "bg-neutral-500");
    }
  }

  function removeClass(el, className) {
    if (el.classList && el) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  function addClass(el, className) {
    if (el) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += " " + className;
      }
    }
  }

  onMount(async () => {
    document.addEventListener("keydown", handleKeyDown);

    const fetchJson = async () => {
      try {
        const res = await fetch("/search.json");
        if (!res.ok) {
          throw new Error("Failed To Fetch");
        }
        const data = await res.json();
        searchData = data;
      } catch (e) {
        console.log(e);
      }
    };
    await fetchJson();
  });
</script>

<div
  class=" text-gray-400 md:px-4 w-full max-w-full mx-auto pb-0.5 md:p-2 md:pt-4"
>
  <div class="flex justify-between items-center max-w-5xl mx-auto">
    <form method="dialog">
      <button class="text-cobalt flex items-center mr-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          ><path
            fill="currentColor"
            d="M17.51 3.87L15.73 2.1L5.84 12l9.9 9.9l1.77-1.77L9.38 12z"
          /></svg
        >Back
      </button>
    </form>
    <div
      class="flex flex-row justify-start w-full mx-1 items-center h-full rounded-xl border border-gray-700"
    >
      <label
        for="svelte-search"
        class="sr-only uppercase tracking-widest font-bold text-xs w-fit"
        >Search</label
      >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 256 256"
        class="hidden sm:inline pl-2"
        ><path
          fill="currentColor"
          d="M232.49 215.51L185 168a92.12 92.12 0 1 0-17 17l47.53 47.54a12 12 0 0 0 17-17ZM44 112a68 68 0 1 1 68 68a68.07 68.07 0 0 1-68-68"
        ></path></svg
      >
      <input
        id="svelte-search"
        class="w-full h-10 pl-2 placeholder:uppercase placeholder:font-bold placeholder:text-xs placeholder:tracking-widest bg-transparent !outline-none text-blue-500 uppercase tracking-widest font-bold text-xs"
        bind:value={searchTerm}
        use:stopTyping
        on:stopTyping={() => fetchSearchResults(searchTerm.trim())}
        on:input={handleInput}
        placeholder="Your search..."
        bind:this={input}
        autofocus
        aria-label="Search"
      />
      <button
      aria-label="Clear Search"
        on:click={() => clearSearchInput()}
        class="p-1 text-xs flex bg-neutral-700 font-bold text-center rounded-full sm:p-2 hover:opacity-70 m-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 1024 1024"
          ><path
            fill="currentColor"
            d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504L738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512L828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496L285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512L195.2 285.696a64 64 0 0 1 0-90.496"
          /></svg
        >
      </button>
    </div>
  </div>
</div>
<div class="text-white max-w-5xl mx-auto text-center">
  <div bind:this={ul}>
    {#if isLoading}
      <div class="flex flex-row space-x-4 w-full justify-center mt-4">
        <!-- Open Ring Spinner s1 -->
        <div
          class="w-12 h-12 rounded-full animate-spin
  border border-solid border-neutral-400 border-t-transparent"
        ></div>
      </div>
    {:else if Object.keys(groupedResults).length}
      {#each Object.entries(groupedResults) as [type, results] (results)}
        <div>
          <h2 class="uppercase text-left p-2 sm:p-4 sm:pb-1 font-semibold">
            {type}
          </h2>
          <ul>
            {#if results.length > 0}
              {#each results as result (result.item.slug)}
                <li
                  transition:fade
                  class="flex flex-row max-w-4xl mx-auto px-1"
                >
                  <div
                    class="p-1 sm:p-2 sm:pl-4 sm:px-4 w-full hover:bg-neutral-800 hover:text-gray-800"
                  >
                    <a
                      href={result.item.slug}
                      class="flex flex-row justify-between"
                    >
                      <div class="flex flex-row gap-2 w-3/4 items-center">
                        <Icon
                          additionalClasses={"pb-1 sm:pb-0.5 mt-1.5 pt-0.5 float-left mr-2"}
                          type={result.item.type}
                        />
                        <p
                          class="text-gray-200 text-xs sm:text-base text-start capitalize pt-1 truncate"
                        >
                          {result.item.title}
                        </p>
                      </div>
                      <p
                        class="text-gray-200 text-xs sm:text-base text-start capitalize pt-1 truncate"
                      >
                        {(result.item.conference_short_name &&
                          result.item.conference_short_name) ||
                          result.item.category}
                      </p>
                    </a>
                  </div>
                </li>
              {/each}
            {/if}
          </ul>
        </div>
      {/each}
    {:else if Object.keys(groupedResults).length === 0}
      <p class="text-gray-400 p-2 pt-4">{placeholder}</p>
    {/if}
  </div>
</div>
